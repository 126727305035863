import logo from '../assets/blackDevils_logo.svg';
import {Link} from 'react-router-dom';
import {createElement, useState} from "react";
import HambugerMenu from "./HambugerMenu"
import BlackDevilsLogo from "./BlackDevilsLogo";

const linkStyle = {
    textDecoration: 'none'
};


const Header = (props) => {
    const [isShowingMenu, showMenu] = useState(false);
    const toggleMenu = () => {
        showMenu((isShowingMenu) => !isShowingMenu);
    }
    return (
        <>
            <nav className="navBar">
                <div className="logoContainerHeader">
                    {createElement(BlackDevilsLogo, {logoText: "Black Devils Vorst", className: "header"})}
                </div>

                <ul className={isShowingMenu ? "navList-showing-menu" : "navList"}>
                    <li className="hambugerMenuListElement" onClick={toggleMenu}><HambugerMenu/></li>
                    <li className={isShowingMenu ? "navListItems-show-menu" : "navListItems"}>
                        <Link to="/" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className='navLinkText'>
                                <div className="antonMedium">NEWS</div>
                            </div>
                        </Link>
                        <Link to="/about" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className="navLinkText">
                                <div className="antonMedium">OVER DE
                                    CLUB
                                </div>
                            </div>
                        </Link>
                        <Link to="/teams" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className="navLinkText">
                                <div className="antonMedium">PLOEGEN</div>
                            </div>
                        </Link>
                        <Link to="/contact" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className="navLinkText">
                                <div className="antonMedium">CONTACT</div>
                            </div>
                        </Link>
                        <Link to="/competition" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className="navLinkText">
                                <div className="antonMedium">WEDSTRIJDEN</div>
                            </div>
                        </Link>
                        <Link to="/documents" style={linkStyle} onClick={isShowingMenu ? toggleMenu : null}>
                            <div className="navLinkText">
                                <div className="antonMedium">DOCUMENTEN</div>
                            </div>
                        </Link>
                    </li>
                </ul>

            </nav>
        </>
    );
}

export default Header