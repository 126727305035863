import logo from '../assets/blackDevils_logo.svg';
import './BlackDevilsLogo.css'

const BlackDevilsLogo = (props) => {
    return (
        <>
            <div className={props.className + "LogoContainer"}>
                <img className={props.className+"Logo"} src={logo} alt="black devils logo"/>
                <div className={props.className+"LogoText"}>{props.logoText}</div>
            </div>
        </>
    );
}
export default BlackDevilsLogo