import './ClubLogo.css'

const ClubLogo = (props) => {
    return (
        <>
            <div className="clubLogoContainer">
                <img className="clubLogo" src={props.logoUrl} alt={props.logoText}/>
                <div className="clubTextLogo">{props.logoText}</div>
            </div>
        </>
    );
}
export default ClubLogo